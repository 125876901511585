import {
  deleteUser,
  getAdminUsers,
  postUser,
  putMeterShedValues,
  putUser,
  resendAccountCreationEmail,
  postPortfolioMemberships,
  deletePortfolioMembership,
} from './_admin';

import { createSlice } from '@reduxjs/toolkit';
import { admin as initialState } from '../initialState';
import { buildAsyncReducers } from '../thunkTemplate';

const { reducer } = createSlice({
  name: 'admin',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    buildAsyncReducers(builder, [
      deleteUser,
      getAdminUsers,
      postUser,
      putMeterShedValues,
      putUser,
      resendAccountCreationEmail,
      postPortfolioMemberships,
      deletePortfolioMembership,
    ]);
  },
});
export {
  getAdminUsers,
  postUser,
  putUser,
  deleteUser,
  resendAccountCreationEmail,
  putMeterShedValues,
  postPortfolioMemberships,
  deletePortfolioMembership,
};
export default reducer;
