import { createAsyncThunk } from '@reduxjs/toolkit';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import { toastr } from 'react-redux-toastr';
import dayjs from 'dayjs';
import {
  cloneDeep,
  concat,
  each,
  filter,
  find,
  get,
  isNull,
  map,
  remove,
  toArray,
} from 'lodash';

import {
  getMetersAPI,
  deleteMeterAPI,
  postMeterAPI,
  putMeterAPI,
  refreshMeterAPI,
} from '../../api';
import { updateNodes } from '../nodes';
import { getLatestInterval } from '../../helpers/dates';

const getMeters = createAsyncThunk(
  'meters/getMeters',
  async (_, { getState, requestId }) => {
    const { currentRequestId, loading } = getState().meters;
    if (loading !== true || requestId !== currentRequestId) {
      return;
    }

    return { data: await getMetersAPI() };
  }
);

const refreshMeters = createAsyncThunk(
  'meters/refreshMeters',
  async (meterIds, { getState, dispatch, requestId }) => {
    const { data: meters, loading, currentRequestId } = getState().meters;
    let allMeters = cloneDeep(meters);

    if (!loading || requestId !== currentRequestId) {
      return;
    }

    try {
      const latestInterval = getLatestInterval();
      const _meters = filter(
        map(toArray(meterIds), (meterId) => {
          return find(meters, { meter_id: meterId });
        }),
        (meter) => {
          if (isNull(meter) || !meter.active) return false;

          let lastRefresh = get(meter, 'lastRefresh');
          return !lastRefresh || lastRefresh.isBefore(latestInterval);
        }
      );

      // refresh meters
      if (_meters.length > 0) {
        dispatch(showLoading());
        console.info(
          `REFRESH :: ${_meters.length} METERS :: `,
          dayjs().format('MM-DD HH:mm:ss')
        );
        let resolvedMeters = await Promise.all(
          map(_meters, async (meter) => {
            const _meter = await refreshMeterAPI(meter.org_id, meter.meter_id);
            return {
              ..._meter,
              lastRefresh: dayjs(),
            };
          })
        ).then((updatedMeters) => {
          each(updatedMeters, (updatedMeter) => {
            remove(allMeters, {
              meter_id: get(updatedMeter, 'meter_id'),
            });
          });

          return concat(allMeters, updatedMeters);
        });
        return { data: resolvedMeters };
      }
    } catch (err) {
      console.error('Meter refresh failed');
    } finally {
      dispatch(hideLoading());
    }
  }
);

const postMeter = createAsyncThunk(
  'meters/postMeter',
  async (meter, { dispatch, getState, requestId }) => {
    let meters = [];
    try {
      const { currentRequestId, loading, data: allMeters } = getState().meters;
      meters = cloneDeep(allMeters);

      if (loading !== true || requestId !== currentRequestId) {
        return;
      }

      dispatch(showLoading());
      let newMeter = await postMeterAPI(meter);

      toastr.success('Meter created', get(newMeter, 'name'));
      meters = concat(meters, newMeter);
      dispatch(updateNodes({ meters }));

      return { data: meters };
    } catch (err) {
      console.error(err);
    } finally {
      dispatch(hideLoading());
    }
  }
);

const putMeter = createAsyncThunk(
  'meters/putMeter',
  async (meter, { dispatch, getState, requestId }) => {
    const { currentRequestId, loading, data: allMeters } = getState().meters;
    let meters = cloneDeep(allMeters);

    try {
      if (loading !== true || requestId !== currentRequestId) {
        return;
      }

      dispatch(showLoading());

      let updatedMeter = await putMeterAPI(meter);
      remove(meters, { meter_id: get(updatedMeter, 'meter_id') });
      meters = concat(meters, updatedMeter);
      toastr.success('Meter updated', get(updatedMeter, 'name'));
      dispatch(updateNodes({ meters }));

      return { data: meters };
    } catch (err) {
      toastr.error(err.message, err.response.data.reason);
    } finally {
      dispatch(hideLoading());
    }
  }
);

const deleteMeter = createAsyncThunk(
  'meters/deleteMeter',
  async (meter, { dispatch, getState, requestId }) => {
    let meters = [];
    try {
      const { currentRequestId, loading, data: allMeters } = getState().meters;
      meters = cloneDeep(allMeters);

      if (loading !== true || requestId !== currentRequestId) {
        return;
      }
      dispatch(showLoading());

      await deleteMeterAPI(get(meter, 'meter_id'));
      toastr.success('Meter deleted', get(meter, 'name'));
      remove(meters, { meter_id: get(meter, 'meter_id') });
      dispatch(updateNodes({ meters }));

      return { data: meters };
    } catch (err) {
      console.error(err);
    } finally {
      dispatch(hideLoading());
    }
  }
);

export { getMeters, postMeter, putMeter, deleteMeter, refreshMeters };
