import http from 'axios';
import { getCurrentSession } from '../helpers/auth';

const WEB_API_URL = `https://${process.env.REACT_APP_WEB_API_ID}.execute-api.${process.env.REACT_APP_REGION}.amazonaws.com/`;

const web = http.create({
  baseURL: `${WEB_API_URL}v1/web`,
  timeout: 20000,
  headers: {
    'Content-Type': 'application/json',
  },
});

web.interceptors.request.use(async function (config) {
  const { accessToken } = await getCurrentSession();

  if (accessToken) {
    config.headers.Authorization = accessToken;
  }
  return config;
});

export default web;
