import { createSlice } from '@reduxjs/toolkit';
import get from 'lodash/get';
import {
  authenticateUser,
  logoutUser,
  putUser,
  createSubscriptions,
  deleteSubscriptions,
} from './_users';

import { user as initialState } from '../initialState';
import { buildAsyncReducers } from '../thunkTemplate';

const { reducer, actions } = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.item = action.payload;
      return state;
    },
    toggleThemeMode: (state) => {
      const item = { ...state.item };

      const currentMode = get(item, 'theme_mode', 'light');
      state.item = {
        ...item,
        theme_mode: currentMode === 'light' ? 'dark' : 'light',
      };
      return state;
    },
  },
  extraReducers: (builder) => {
    buildAsyncReducers(builder, [
      authenticateUser,
      logoutUser,
      putUser,
      createSubscriptions,
      deleteSubscriptions,
    ]);
  },
});

const { setUser, toggleThemeMode } = actions;
export {
  authenticateUser,
  logoutUser,
  setUser,
  putUser,
  createSubscriptions,
  deleteSubscriptions,
  toggleThemeMode,
};
export default reducer;
