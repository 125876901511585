import { createAsyncThunk } from '@reduxjs/toolkit';
import { hideLoading, showLoading } from 'react-redux-loading-bar';
import { toastr } from 'react-redux-toastr';
import { cloneDeep, concat, get, remove } from 'lodash';

import {
  deleteOrganizationAPI,
  getOrganizationsAPI,
  getPortfolioMembershipsAPI,
  postOrganizationAPI,
  putOrganizationAPI,
} from '../../api';

import { getMeters } from '../meters';
import { getSites } from '../sites';
import { getMemberships } from '../memberships';
import { getNiagaras } from '../niagaras';
import { getAdminUsers } from '../admin';
import { setLoader } from '../pages';
import { getLicenses } from '../licenses';

const getOrganizations = createAsyncThunk(
  'organization/getOrganizations',
  async (user, { dispatch, getState, requestId }) => {
    try {
      const { loading, currentRequestId } = getState().organizations;

      if (!user) {
        const { item: data } = getState().user;
        user = { ...data };
      }

      if (loading !== true || requestId !== currentRequestId) {
        return;
      }

      dispatch(getSites());
      dispatch(getMeters());
      dispatch(getMemberships());
      dispatch(getLicenses());

      if (user.super_user) {
        dispatch(getAdminUsers(user));
        dispatch(getNiagaras());
      }

      return {
        data: await getOrganizationsAPI(),
        portfolioMembers: await getPortfolioMembershipsAPI(),
      };
    } catch (err) {
      console.error(err);
    } finally {
      dispatch(setLoader(false));
    }
  }
);

const postOrganization = createAsyncThunk(
  'organization/postOrganization',
  async (organization, { dispatch, getState, requestId }) => {
    try {
      const {
        currentRequestId,
        loading,
        data: allOrgs,
      } = getState().organizations;

      if (loading !== true || requestId !== currentRequestId) {
        return;
      }

      dispatch(showLoading());
      const newOrganization = await postOrganizationAPI(organization);

      toastr.success('Organization created', get(newOrganization, 'name'));
      return {
        data: concat(allOrgs, newOrganization),
      };
    } catch (err) {
      console.error(err);
    } finally {
      dispatch(hideLoading());
    }
  }
);

const putOrganization = createAsyncThunk(
  'organization/putOrganization',
  async (organization, { dispatch, getState, requestId }) => {
    try {
      const {
        currentRequestId,
        loading,
        data: organizations,
      } = getState().organizations;

      if (loading !== true || requestId !== currentRequestId) {
        return;
      }

      dispatch(showLoading());
      let _organizations = cloneDeep(organizations);

      const updatedOrganization = await putOrganizationAPI(organization);
      const organization_id = get(updatedOrganization, 'org_id');
      remove(_organizations, { org_id: organization_id });

      toastr.success('Organization updated');
      return {
        data: concat(_organizations, updatedOrganization),
      };
    } catch (err) {
      const errorMessage = get(err, 'response.data.message', 'Bad Request');
      toastr.error('Update Failed', errorMessage);
    } finally {
      dispatch(hideLoading());
    }
  }
);

const deleteOrganization = createAsyncThunk(
  'organization/deleteOrganization',
  async (organization_id, { dispatch, getState, requestId }) => {
    try {
      const {
        currentRequestId,
        loading,
        data: allOrganizations,
      } = getState().organizations;

      if (loading !== true || requestId !== currentRequestId) {
        return;
      }

      dispatch(showLoading());
      let _organizations = cloneDeep(allOrganizations);
      await deleteOrganizationAPI(organization_id);

      remove(_organizations, { org_id: organization_id });

      toastr.success('Organization deleted');

      return { data: _organizations };
    } catch (err) {
      console.error(err);
    } finally {
      dispatch(hideLoading());
    }
  }
);

export {
  getOrganizations,
  putOrganization,
  postOrganization,
  deleteOrganization,
};
