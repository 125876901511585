import React from 'react';

import styled from '@mui/material/styles/styled';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';

// import VeregyIcon from './VeregyIcon';
// import BenefitItem from './BenefitItem';
// import HowItWorks from './HowItWorks';
// import HeadlineContent from './HeadlineContent';
import AuthenticationLinks from './AuthenticationLinks';
export const background = require(`../../assets/images/power-lines.jpg`);

const Root = styled('div')({
  display: 'flex',
  height: '100%',
  width: '100vw',
  background: `url(${background})`,
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
});

const Main = styled('div')({
  display: 'flex',
  height: '100%',
  width: '100%',
  justifyContent: 'center',
  alignItems: 'center',
});

export default function Authentication() {
  return (
    <Root>
      <CssBaseline />
      <Main>
        <Grid container spacing={3} sx={{ maxWidth: 500, width: '100%' }}>
          <Grid item xs={12} sm={12}>
            <AuthenticationLinks />
          </Grid>
          {/* <Grid item xs={12} sm={6}>
            <HeadlineContent />
          </Grid>
          <Grid item xs={12}>
            <HowItWorks />
          </Grid>
          <Grid item xs={12} sm={4}>
            <BenefitItem
              title='Optimized Performance'
              icon={<VeregyIcon name='Battery' />}>
              Facilisi etiam dignissim diam quis enim lobortis scelerisque
              fermentum dui. Sed nisi lacus sed viverra tellus in hac habitasse.
            </BenefitItem>
          </Grid>
          <Grid item xs={12} sm={4}>
            <BenefitItem
              title='Data Driven Decision Making'
              icon={<VeregyIcon name='Planning' />}>
              Ipsum nunc aliquet bibendum enim facilisis gravida neque convallis
              a. Aliquet lectus proin nibh nisl condimentum faucibus nisl
              tincidunt.
            </BenefitItem>
          </Grid>
          <Grid item xs={12} sm={4}>
            <BenefitItem
              title='Simplified Management'
              icon={<VeregyIcon name='School' />}>
              Pretium vulputate sapien nec sagittis. Commodo nulla facilisi
              nullam vehicula ipsum. Tortor vitae purus faucibus ornare
              suspendisse.
            </BenefitItem>
          </Grid> */}
        </Grid>
      </Main>
    </Root>
  );
}
