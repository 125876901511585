import axios from '../xhr_libs/axios';
import { processResponse } from '.';

export const getSubscriptionsAPI = async (userId) => {
  return processResponse(await axios.get(`/resource/subscriptions/${userId}`));
};

export const postSubscriptionAPI = async ({
  userId,
  orgId,
  deviceId,
  triggerId,
}) => {
  return processResponse(
    await axios.post(`/resource/subscriptions/${userId}`, {
      orgId,
      deviceId,
      triggerId,
    })
  );
};

export const deleteSubscriptionAPI = async (subscriptionId) => {
  return processResponse(
    await axios.delete(`/resource/subscriptions/${subscriptionId}`)
  );
};
