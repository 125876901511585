import { find, get, reduce } from 'lodash';
import dayjs from 'dayjs';

export const extractGoogleAddress = (googlePlaceObj) => {
  if (!googlePlaceObj) return {};
  return {
    address_components: googlePlaceObj.address_components,
    formatted_address: googlePlaceObj.formatted_address,
    place_id: googlePlaceObj.place_id,
    lat: googlePlaceObj.geometry.location.lat(),
    lng: googlePlaceObj.geometry.location.lng(),
  };
};

export const calculateTodaySum = (meters) => {
  return reduce(
    meters,
    (sum, meter) => {
      return sum + get(meter, 'energy_stats.today', 0);
    },
    0
  );
};

export const calculateMonthSum = (demandTables) => {
  let now = dayjs();
  return reduce(
    demandTables,
    (sum, table) => {
      let month = find(table, {
        month: now.month() + 1,
        year: now.year(),
      });
      return sum + get(month, 'value', 0);
    },
    0
  );
};

export const calculateAllTimeSum = (demandTables) => {
  return reduce(
    demandTables,
    (outerSum, table) => {
      return (
        outerSum +
        reduce(table, (innerSum, values) => innerSum + get(values, 'value'), 0)
      );
    },
    0
  );
};

export const getYAxisMax = (dataMax) => {
  if (dataMax < 1) {
    return 1;
  } else {
    return Math.ceil(dataMax / 10) * 10;
  }
};

export const renderLatLngValue = (value) => {
  if (!value) return '';
  return value.toFixed(2) + '°';
};

export const effectiveStatus = (license) => {
  if (!license) return false;
  if (license.force_active === false) return false;
  if (license.force_active === true) return true;
  return license.active;
};
