import axios from '../xhr_libs/axios';
import { processResponse } from '.';

export const getPortfolioMembershipsAPI = async () => {
  return processResponse(await axios.get(`/resource/portfolio_memberships`));
};

export const postPortfolioMembershipAPI = async (portfolioId, memberIds) => {
  return processResponse(
    await axios.post(
      `/resource/portfolio_memberships/${portfolioId}`,
      memberIds
    )
  );
};

export const deletePortfolioMembershipAPI = async (portfolioId, memberId) => {
  return processResponse(
    await axios.delete(
      `/resource/portfolio_memberships/${portfolioId}/${memberId}`
    )
  );
};
