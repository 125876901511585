export * from './alarms';
export * from './kiosk';
export * from './kpis';
export * from './licenses';
export * from './memberships';
export * from './meters';
export * from './niagaras';
export * from './organizations';
export * from './portfolio_memberships';
export * from './rtos';
export * from './sites';
export * from './subscriptions';
export * from './users';
export * from './weather';

export const processResponse = (response) => {
  if (response?.data?.result === 'success') {
    return response?.data?.value;
  }
};
