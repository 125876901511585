import React from 'react';

import Container from './Container';
import TermsContent from './TermsContent';

export default function Terms() {
  return (
    <Container>
      <TermsContent />
    </Container>
  );
}
