import axios from '../xhr_libs/axios';
import { processResponse } from '.';

export const refreshMeterAPI = async (orgId, meterId) => {
  return processResponse(
    await axios.get(`/resource/refresh_meter/${orgId}/${meterId}`)
  );
};

export const getMetersAPI = async () => {
  return processResponse(await axios.get('/resource/meters'));
};

export const postMeterAPI = async (body) => {
  return processResponse(await axios.post('/resource/meters', body));
};

export const putMeterAPI = async (body) => {
  return processResponse(
    await axios.put(`/resource/meters/${body.meter_id}`, body)
  );
};

export const deleteMeterAPI = async (meter_id) => {
  return processResponse(await axios.delete(`/resource/meters/${meter_id}`));
};

export const getMeterDemandAPI = async (meterId, start, end) => {
  return processResponse(
    await axios.get(`/resource/demand/${meterId}`, {
      params: { start, end },
    })
  );
};

export const getMeterDemandSumAPI = async (
  orgId,
  meterId,
  sumType,
  start,
  end
) => {
  return processResponse(
    await axios.get(`/resource/demand-sum/${orgId}/${meterId}`, {
      params: { start, end, type: sumType },
    })
  );
};
