import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faAnalytics as faAnalyticsLight,
  faArrowToLeft as faArrowToLeftLight,
  faArrowToBottom as faArrowToBottomLight,
  faBars as faBarsLight,
  faBellOn as faBellOnLight,
  faBellSlash as faBellSlashLight,
  faBolt as faBoltLight,
  faBuilding as faBuildingLight,
  faBuildings as faBuildingsLight,
  faCalendarAlt as faCalendarAltLight,
  faCalendarClock as faCalendarClockLight,
  faCalendarDay as faCalendarDayLight,
  faCalendarRange as faCalendarRangeLight,
  faCalendars as faCalendarsLight,
  faCaretDown as faCaretDownLight,
  faCaretRight as faCaretRightLight,
  faChartLine as faChartLineLight,
  faChevronLeft as faChevronLeftLight,
  faChevronRight as faChevronRightLight,
  faClock as faClockLight,
  faCog as faCogLight,
  faCopy as faCopyLight,
  faDoNotEnter as faDoNotEnterLight,
  faEdit as faEditLight,
  faEllipsisV as faEllipsisVLight,
  faEye as faEyeLight,
  faEyeSlash as faEyeSlashLight,
  faFilter as faFilterLight,
  faFolder as faFolderLight,
  faHistory as faHistoryLight,
  faInfoCircle as faInfoCircleLight,
  faLongArrowRight as faLongArrowRight,
  faList as faListLight,
  faMinusCircle as faMinusCircleLight,
  faPause as faPauseLight,
  faPlay as faPlayLight,
  faPlusCircle as faPlusCircleLight,
  faQuestionCircle as faQuestionCircleLight,
  faRadar as faRadarLight,
  faRotateRight as faRotateRightLight,
  faRulerCombined as faRulerCombinedLight,
  faSave as faSaveLight,
  faSearch as faSearchLight,
  faShieldAlt as faShieldAltLight,
  faSignal1 as faSignal1Light,
  faSignal2 as faSignal2Light,
  faSignal3 as faSignal3Light,
  faSignal4 as faSignal4Light,
  faSignOut as faSignOutLight,
  faStore as faStoreLight,
  faTimes as faTimesLight,
  faTrash as faTrashLight,
  faUndo as faUndoLight,
  faUserCircle as faUserCircleLight,
  faXmark as faXmarkLight,
} from '@fortawesome/pro-light-svg-icons';
import { faGithub } from '@fortawesome/free-brands-svg-icons';

export default function initIcons() {
  library.add(
    faAnalyticsLight,
    faArrowToLeftLight,
    faArrowToBottomLight,
    faBarsLight,
    faBellOnLight,
    faBellSlashLight,
    faBoltLight,
    faBuildingLight,
    faBuildingsLight,
    faCalendarAltLight,
    faCalendarClockLight,
    faCalendarDayLight,
    faCalendarRangeLight,
    faCalendarsLight,
    faCaretDownLight,
    faCaretRightLight,
    faChartLineLight,
    faChevronLeftLight,
    faChevronRightLight,
    faClockLight,
    faCogLight,
    faCopyLight,
    faDoNotEnterLight,
    faEditLight,
    faEllipsisVLight,
    faEyeLight,
    faEyeSlashLight,
    faFilterLight,
    faFolderLight,
    faGithub,
    faHistoryLight,
    faInfoCircleLight,
    faListLight,
    faLongArrowRight,
    faMinusCircleLight,
    faPauseLight,
    faPlayLight,
    faPlusCircleLight,
    faQuestionCircleLight,
    faRadarLight,
    faRotateRightLight,
    faRulerCombinedLight,
    faSaveLight,
    faSearchLight,
    faShieldAltLight,
    faSignal1Light,
    faSignal2Light,
    faSignal3Light,
    faSignal4Light,
    faSignOutLight,
    faStoreLight,
    faTimesLight,
    faTrashLight,
    faUndoLight,
    faUserCircleLight,
    faXmarkLight
  );
}
