import { createAsyncThunk } from '@reduxjs/toolkit';
import { hideLoading, showLoading } from 'react-redux-loading-bar';
import { toastr } from 'react-redux-toastr';
import { cloneDeep, concat, get, includes, map, remove } from 'lodash';

import {
  deletePortfolioMembershipAPI,
  deleteUserAPI,
  getUsersAPI,
  postPortfolioMembershipAPI,
  postUserAPI,
  putMeterAPI,
  putUserAPI,
  resendAccountCreationEmailAPI,
} from '../../api';

import { getOrganizations } from '../organizations';
import { setMeters } from '../meters';
import { setUser } from '../user';
import { updateNodes } from '../nodes';

const getAdminUsers = createAsyncThunk(
  'admin/getAdminUsers',
  async (user, { dispatch, getState, requestId }) => {
    try {
      const { currentRequestId, loading } = getState().admin;

      if (loading !== true || requestId !== currentRequestId) {
        return;
      }

      dispatch(showLoading());

      // Determine what to do with the user
      if (get(user, 'super_user')) {
        let { users, relations } = await getUsersAPI(user);
        return { users, memberships: relations };
      }

      return {};
    } catch (err) {
      console.error(err);
    } finally {
      dispatch(hideLoading());
    }
  }
);

const postUser = createAsyncThunk(
  'admin/postUser',
  async (newUser, { dispatch, getState, requestId }) => {
    try {
      const { item: user } = getState().user;
      const { currentRequestId, loading, users } = getState().admin;
      const super_user = get(user, 'super_user', false);

      if (!super_user || loading !== true || requestId !== currentRequestId) {
        return;
      }

      let _users = cloneDeep(users);

      dispatch(showLoading());
      let _newUser = await postUserAPI(newUser);

      toastr.success('User created', get(_newUser, 'email'));

      return { users: concat(_users, _newUser) };
    } catch (err) {
      console.error(err);
    } finally {
      dispatch(hideLoading());
    }
  }
);

const putUser = createAsyncThunk(
  'admin/putUser',
  async (data, { dispatch, getState, requestId }) => {
    try {
      const { currentRequestId, loading, users } = getState().admin;
      const { item: user } = getState().user;
      const super_user = get(user, 'super_user', false);

      // User can update their user or be a super user to update actual user data
      if (
        (!super_user && data.user_id !== user.user_id) ||
        loading !== true ||
        requestId !== currentRequestId
      ) {
        return;
      }
      dispatch(showLoading());

      let updatedUser = await putUserAPI(data.user_id, data);
      toastr.success('User updated', get(updatedUser, 'email'));

      if (user.user_id === data.user_id) dispatch(setUser(updatedUser));

      let _users = cloneDeep(users);
      remove(_users, { user_id: get(updatedUser, 'user_id') });

      return { users: concat(_users, updatedUser) };
    } catch (err) {
      toastr.error(
        'Failed to update User',
        get(err, 'response.data.reason', 'Bad Request')
      );
    } finally {
      dispatch(hideLoading());
    }
  }
);

const deleteUser = createAsyncThunk(
  'admin/deleteUser',
  async (userId, { dispatch, getState, requestId }) => {
    try {
      const { item: user } = getState().user;
      const { currentRequestId, loading, users } = getState().admin;
      const super_user = get(user, 'super_user', false);

      if (!super_user || loading !== true || requestId !== currentRequestId) {
        return;
      }

      let _users = cloneDeep(users);

      dispatch(showLoading());
      await deleteUserAPI(userId);

      remove(_users, { user_id: userId });
      toastr.success('User deleted');

      return { users: _users };
    } catch (err) {
      console.error(err);
    } finally {
      dispatch(hideLoading());
    }
  }
);

const resendAccountCreationEmail = createAsyncThunk(
  'admin/resendAccountCreationEmail',
  async (_user, { dispatch, getState, requestId }) => {
    try {
      const { currentRequestId, loading } = getState().admin;
      const { item: user } = getState().user;
      const super_user = get(user, 'super_user', false);

      if (!super_user || loading !== true || requestId !== currentRequestId) {
        return;
      }

      dispatch(showLoading());
      const message = await resendAccountCreationEmailAPI(_user);
      toastr.success(message, get(_user, 'email'));

      return;
    } catch (err) {
      toastr.error('Error', get(err, 'response.data.reason', err));
    } finally {
      dispatch(hideLoading());
    }
  }
);

const putMeterShedValues = createAsyncThunk(
  'admin/putMeterShedValues',
  async ({ shed, meters }, { dispatch, getState, requestId }) => {
    try {
      const { item: user } = getState().user;
      const { currentRequestId, loading } = getState().admin;
      const { data: allMeters } = getState().meters;
      const super_user = get(user, 'super_user', false);

      if (!super_user || loading !== true || requestId !== currentRequestId) {
        return;
      }

      meters = map(meters, (meter) => {
        return {
          ...meter,
          shed: !(shed === true || shed === false) ? null : Boolean(shed),
        };
      });

      let _meters = cloneDeep(allMeters);

      dispatch(showLoading());
      Promise.all(
        map(meters, (meter) => {
          return putMeterAPI(meter);
        })
      ).then((updatedMeters) => {
        let updatedMeterIds = map(updatedMeters, (meter) =>
          get(meter, 'meter_id')
        );
        remove(_meters, (meter) =>
          includes(updatedMeterIds, get(meter, 'meter_id'))
        );

        dispatch(setMeters(concat(_meters, updatedMeters)));
        toastr.success('Meters updated');
        return {};
      });
    } catch (err) {
      console.error(err);
    } finally {
      dispatch(hideLoading());
    }
  }
);

const postPortfolioMemberships = createAsyncThunk(
  'organization/postPortfolioMemberships',
  async ({ portfolioId, memberIds }, { dispatch, getState, requestId }) => {
    try {
      const { currentRequestId, loading } = getState().admin;

      if (loading !== true || requestId !== currentRequestId) {
        return;
      }

      dispatch(showLoading());
      await postPortfolioMembershipAPI(portfolioId, memberIds);
      const message = memberIds.length > 1 ? 'Organizations' : 'Organization';
      toastr.success(`${message} added to Portfolio`);

      const {
        payload: { data: organizations, portfolioMembers },
      } = await dispatch(getOrganizations());

      dispatch(updateNodes({ organizations, portfolioMembers }));

      return;
    } catch (err) {
      toastr.error('Error', get(err, 'response.data.reason', err));
    } finally {
      dispatch(hideLoading());
    }
  }
);

const deletePortfolioMembership = createAsyncThunk(
  'organization/deletePortfolioMembership',
  async ({ portfolioId, memberId }, { dispatch, getState, requestId }) => {
    try {
      const { currentRequestId, loading } = getState().admin;

      if (loading !== true || requestId !== currentRequestId) {
        return;
      }

      dispatch(showLoading());
      await deletePortfolioMembershipAPI(portfolioId, memberId);
      toastr.success('Organization removed from Portfolio');

      const {
        payload: { data: organizations, portfolioMembers },
      } = await dispatch(getOrganizations());

      dispatch(
        updateNodes({
          organizations,
          portfolioMembers,
        })
      );

      return;
    } catch (err) {
      toastr.error('Error', get(err, 'response.data.reason', err));
    } finally {
      dispatch(hideLoading());
    }
  }
);

export {
  getAdminUsers,
  postUser,
  putUser,
  deleteUser,
  resendAccountCreationEmail,
  putMeterShedValues,
  postPortfolioMemberships,
  deletePortfolioMembership,
};
