import React from 'react';

import Container from './Container';
import PrivacyContent from './PrivacyContent';

export default function Privacy() {
  return (
    <Container>
      <PrivacyContent />
    </Container>
  );
}
