import axios from '../xhr_libs/axios';
import { processResponse } from '.';

export const getSitesAPI = async () => {
  return processResponse(await axios.get('/resource/sites'));
};

export const postSiteAPI = async (body) => {
  return processResponse(await axios.post('/resource/sites', body));
};

export const putSiteAPI = async (body) => {
  return processResponse(
    await axios.put(`/resource/sites/${body.site_id}`, body)
  );
};

export const deleteSiteAPI = async (siteId) => {
  return processResponse(await axios.delete(`/resource/sites/${siteId}`));
};
