import { createAsyncThunk } from '@reduxjs/toolkit';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import { toastr } from 'react-redux-toastr';
import cloneDeep from 'lodash/cloneDeep';
import concat from 'lodash/concat';
import get from 'lodash/get';
import remove from 'lodash/remove';

import {
  postMembershipAPI,
  putMembershipAPI,
  deleteMembershipAPI,
  getMembershipsAPI,
} from '../../api';

const getMemberships = createAsyncThunk(
  'memberships/getMemberships',
  async () => {
    try {
      return { data: await getMembershipsAPI() };
    } catch (err) {
      console.error(err);
    }
  }
);

const postMembership = createAsyncThunk(
  'memberships/postMembership',
  async (item, { dispatch, getState }) => {
    const { data: orgUsers } = getState().memberships;
    let _orgUsers = cloneDeep(orgUsers);

    try {
      dispatch(showLoading());
      let newMembership = await postMembershipAPI(item);

      toastr.success('User Membership created');
      return { data: concat(_orgUsers, newMembership) };
    } catch (err) {
      toastr.error('Error', get(err, 'response.data.reason', err));
    } finally {
      dispatch(hideLoading());
    }
  }
);

const putMembership = createAsyncThunk(
  'memberships/putMembership',
  async (item, { dispatch, getState }) => {
    const { data: orgUsers } = getState().memberships;
    let _orgUsers = cloneDeep(orgUsers);

    try {
      dispatch(showLoading());
      let updatedMembership = await putMembershipAPI(item);

      remove(_orgUsers, {
        org_id: get(updatedMembership, 'org_id'),
        user_id: get(updatedMembership, 'user_id'),
      });

      toastr.success('User permissions updated');

      return { data: concat(_orgUsers, updatedMembership) };
    } catch (err) {
      toastr.error('Error', get(err, 'response.data.reason', err));
    } finally {
      dispatch(hideLoading());
    }
  }
);

const deleteMembership = createAsyncThunk(
  'memberships/deleteMembership',
  async (item, { dispatch, getState }) => {
    const { data: orgUsers } = getState().memberships;
    let _orgUsers = cloneDeep(orgUsers);
    const org_id = get(item, 'org_id');
    const user_id = get(item, 'user_id');

    try {
      dispatch(showLoading());
      let deletedMembership = await deleteMembershipAPI(org_id, user_id);

      remove(_orgUsers, {
        org_id: get(deletedMembership, 'org_id'),
        user_id: get(deletedMembership, 'user_id'),
      });

      toastr.success('Removed user from Organization');
      return { data: _orgUsers };
    } catch (err) {
      toastr.error('Error', get(err, 'response.data.reason', err));
    } finally {
      dispatch(hideLoading());
    }
  }
);

export { getMemberships, postMembership, putMembership, deleteMembership };
