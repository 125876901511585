import axios from '../xhr_libs/axios';
import { processResponse } from '.';

export const getKioskDataAPI = async (type, kioskCode) => {
  return processResponse(await axios.get(`/kiosk/${type}/${kioskCode}`));
};

export const getKioskDeviceTimeseriesAPI = async (
  type,
  kioskCode,
  deviceId
) => {
  return processResponse(
    await axios.get(`/kiosk/${type}/${kioskCode}/${deviceId}`)
  );
};

export const getKioskChartDataAPI = async (
  type,
  kioskCode,
  meterId,
  start,
  end
) => {
  if (type === 'org') type = 'organization';
  return processResponse(
    await axios.get(`/kiosk/${type}/${kioskCode}/${meterId}/${start}/${end}`)
  );
};

export const getKioskDailyDemandAPI = async (
  type,
  kioskCode,
  meterId,
  month
) => {
  return processResponse(
    await axios.get(`/kiosk/${type}/${kioskCode}/${meterId}/${month}`)
  );
};

export const getKioskImagesAPI = async (type, kioskCode) => {
  return processResponse(await axios.get(`/kiosk/images/${type}/${kioskCode}`));
};
