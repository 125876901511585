import React from 'react';

import PropTypes from 'prop-types';

import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';

const Title = ({ children }) => (
  <ListItem sx={{ p: 0, pb: 1 }}>
    <Typography variant='h4'>{children}</Typography>
  </ListItem>
);

Title.propTypes = {
  children: PropTypes.string,
};

export default Title;
