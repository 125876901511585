import { getNiagaras, deleteNiagara, putNiagara } from './_niagaras';

import { createSlice } from '@reduxjs/toolkit';
import { niagaras as initialState } from '../initialState';
import { buildAsyncReducers } from '../thunkTemplate';

const { reducer, actions } = createSlice({
  name: 'niagaras',
  initialState,
  reducers: {
    setNiagaras: (state, { payload }) => ({
      ...state,
      data: payload,
    }),
  },
  extraReducers: (builder) => {
    buildAsyncReducers(builder, [getNiagaras, putNiagara, deleteNiagara]);
  },
});

const { setNiagaras } = actions;

export { getNiagaras, putNiagara, deleteNiagara, setNiagaras };
export default reducer;
