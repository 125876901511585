import dayjs from 'dayjs';

import advancedFormat from 'dayjs/plugin/advancedFormat';
import dayjsPluginUTC from 'dayjs-plugin-utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(dayjsPluginUTC);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);

function getMonthName(monthNumber) {
  const date = new Date();
  date.setMonth(monthNumber - 1);
  return date.toLocaleString('default', { month: 'long' });
}

function UTCDateStringToLocalDateString(value) {
  const formatString = 'YYYY-MM-DDTHH:mm:ss.SSSZ';
  const parsedDate = dayjs.utc(value).format(formatString);
  return dayjs(parsedDate).tz().format('MMMM D, YYYY HH:mm z');
}

const toTimestamp = (day, time, offset) => {
  let [hours, minutes] = time.split(':');
  let localOffset = dayjs().utcOffset() / 60;
  let start = day.utc().startOf('day').add(localOffset, 'hour');
  start = start
    .subtract(offset, 'hour')
    .add(Number(hours), 'hour')
    .add(Number(minutes), 'minute');

  return start.unix();
};

const getLatestInterval = () => {
  let now = dayjs();
  return now
    .minute(now.minute() - (now.minute() % 15))
    .second(0)
    .millisecond(0);
};

const getLocalTimzone = () => {
  const localTime = dayjs().tz();
  const abbreviation = localTime.format('z');
  const offset = parseInt(localTime.format('Z'), 10);

  return { name: abbreviation, zone: 'America/New_York', offset: offset };
};

export {
  getMonthName,
  UTCDateStringToLocalDateString,
  toTimestamp,
  getLatestInterval,
  getLocalTimzone,
};
