import axios from '../xhr_libs/axios';
import { processResponse } from '.';

export const getKPITimeseriesAPI = async (metrics, start, end) => {
  return processResponse(
    await axios.get('/resource/kpis', {
      params: {
        start,
        end,
        metrics: JSON.stringify(metrics),
      },
    })
  );
};

export const getKPIsAPI = async (metric, start, end) => {
  return processResponse(
    await axios.get(`/resource/kpis/${metric}`, {
      params: {
        start,
        end,
      },
    })
  );
};
