import axios from '../xhr_libs/axios';
import { processResponse } from '.';

export const getRTOsAPI = async () => {
  return processResponse(await axios.get('/resource/rtos'));
};

export const putRTOAPI = async (rto) => {
  return processResponse(await axios.put(`/resource/rtos/${rto.rto_id}`, rto));
};

export const getRTODataFromFeedAPI = async (dataFeed, rtoId, start, end) => {
  return processResponse(
    await axios.get(`/resource/${dataFeed}/${rtoId}`, {
      params: { start, end },
    })
  );
};

export const getPeakDataAPI = async (rto_id) => {
  return processResponse(await axios.get(`/resource/peaks/${rto_id}`));
};
