import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import traverse from 'traverse';
import { nodes as initialState } from '../initialState';

import each from 'lodash/each';
import filter from 'lodash/filter';
import find from 'lodash/find';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import isObject from 'lodash/isObject';
import get from 'lodash/get';
import map from 'lodash/map';
import orderBy from 'lodash/orderBy';
import sortBy from 'lodash/sortBy';
import uniqueId from 'lodash/uniqueId';

import { buildAsyncReducers } from '../thunkTemplate';
import { navigate, setPage } from '../pages';
import { effectiveStatus } from '../../helpers';

const generatePortfolioTree = (
  portfolio,
  allPortfolioMembers,
  organizations,
  licenses,
  sites,
  meters
) => {
  const portfolioMembers = filter(allPortfolioMembers, {
    portfolio_id: portfolio.org_id,
  });
  const memberOrgs = [];

  each(portfolioMembers, (portfolioMember) => {
    let organization = find(organizations, {
      org_id: portfolioMember.member_id,
    });
    if (organization) {
      memberOrgs.push(organization);
    }
  });

  return {
    type: 'tree-node-parent',
    resourceType: 'portfolio',
    label: get(portfolio, 'name'),
    itemId: get(portfolio, 'org_id'),
    hide: false,
    icon: ['fal', 'folder'],
    children: memberOrgs.map((org) =>
      generateOrgTree(org, licenses, sites, meters)
    ),
  };
};

const generateOrgTree = (organization, licenses, sites, meters) => {
  const orgSites = filter(sites, { org_id: organization.org_id });

  const license = find(licenses, {
    org_id: organization.org_id,
    name: 'standard',
  });
  const children = !effectiveStatus(license)
    ? []
    : map(orgSites, (site) => generateSiteTree(site, meters));
  return {
    type: 'tree-node-organization',
    label: organization.name,
    resourceType: 'organization',
    itemId: organization.org_id,
    icon: ['fal', 'buildings'],
    children,
  };
};

const generateSiteTree = (site, meters) => {
  const siteMeters = filter(meters, { site_id: site.site_id });

  return {
    type: 'tree-node-site',
    label: site.name,
    resourceType: 'site',
    itemId: site.site_id,
    icon: ['fal', 'building'],
    children: map(siteMeters, (meter) => generateMeterTree(meter)),
  };
};

const generateMeterTree = (meter) => {
  return {
    type: 'tree-node-meter',
    label: meter.name,
    resourceType: 'meter',
    itemId: meter.meter_id,
    icon: ['fal', 'bolt'],
    children: [],
  };
};

const updateNodes = createAsyncThunk(
  'nodes/update',
  (
    { organizations, portfolioMembers, licenses, sites, meters, nav = false },
    { getState, dispatch }
  ) => {
    let { item: user } = getState().user;
    let { data: sites_ } = getState().sites;
    let { data: licenses_ } = getState().licenses;
    let { data: meters_ } = getState().meters;
    let { portfolioMembers: portfolioMembers_, data: organizations_ } =
      getState().organizations;

    sites = isArray(sites) ? sites : sites_;
    licenses = isArray(licenses) ? licenses : licenses_;
    meters = isArray(meters) ? meters : meters_;
    portfolioMembers = isArray(portfolioMembers)
      ? portfolioMembers
      : portfolioMembers_;
    organizations = isArray(organizations) ? organizations : organizations_;

    let nodes = [];

    const portfolios = sortBy(
      filter(organizations, { is_portfolio: true }),
      'name'
    );

    if (!isEmpty(portfolios)) {
      nodes = map(portfolios, (portfolio) =>
        generatePortfolioTree(
          portfolio,
          portfolioMembers,
          organizations,
          licenses,
          sites,
          meters
        )
      );
    } else {
      nodes = map(organizations, (org) =>
        generateOrgTree(org, licenses, sites, meters)
      );
    }

    if (user.default_organization && nav) {
      const defaultOrg = find(organizations, {
        org_id: user.default_organization,
      });

      if (isObject(defaultOrg)) {
        if (get(defaultOrg, 'is_portfolio', false)) {
          dispatch(setPage({ page: 'portfolio', id: defaultOrg.org_id }));
        } else {
          dispatch(
            navigate({
              page: 'organization',
              id: defaultOrg.org_id,
            })
          );
        }
      }
    }

    nodes = traverse(nodes).map(function (node) {
      if (this.notLeaf && !isArray(node)) {
        node.level = this.level;
        node.id = node.itemId;
        node.itemId = node.itemId + '.' + uniqueId();
      }
      if (node.children) {
        node.children = orderBy(node.children, ['type', 'label']);
      }
      return node;
    });
    return { data: nodes };
  }
);

// NOTE: "Mutating" state is safe in redux toolkit because it uses Immer
const { reducer, actions } = createSlice({
  name: 'nodes',
  initialState,
  reducers: {
    setNodes(state, action) {
      state.data = action.payload;
    },
  },
  extraReducers: (builder) => {
    buildAsyncReducers(builder, [updateNodes]);
  },
});

const { setNodes } = actions;

// Export the reducer, either as a default or named export
export { updateNodes, setNodes };
export default reducer;
