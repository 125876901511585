import axios from 'axios';
import { refreshTokens } from './cognito';
import { getTokens, setTokens } from './tokens';

const APP_ID = 'demand';
const ENV = process.env.REACT_APP_ENV;
const REGION = process.env.REACT_APP_REGION;
const WEBCLIENT_ID = process.env.REACT_APP_USER_POOL_WEBCLIENT_ID;
const WEB_URL = process.env.REACT_APP_WEB_URL;
const DOMAIN = `https://orchestrate-${ENV}-user-pool.auth.${REGION}.amazoncognito.com`;

const getCurrentSession = async () => {
  const now = Math.round(new Date().getTime() / 1000);
  const { refreshToken, accessToken, idToken, exprTime } = getTokens();

  // if accessand id tokens have not expired, return values from localStorage
  if (now < exprTime && accessToken) {
    return {
      accessToken,
      idToken,
      exprTime: exprTime,
    };
  }

  if (refreshToken) {
    return await refreshTokens(refreshToken);
  }

  throw new Error('No current session');
};

const handleAuthCode = async (code) => {
  const queryString = Object.entries({
    code,
    redirect_uri: `${WEB_URL}app`,
    grant_type: 'authorization_code',
    client_id: WEBCLIENT_ID,
  })
    .map(([k, v]) => `${encodeURIComponent(k)}=${encodeURIComponent(v)}`)
    .join('&');

  const response = await axios.post(
    `${DOMAIN}/oauth2/token?${queryString}`,
    {},
    {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    }
  );

  const payload = response.data;
  const refreshToken = payload.refresh_token;
  const accessToken = payload.access_token;
  const idToken = payload.od_token;

  setTokens({
    RefreshToken: refreshToken,
    AccessToken: accessToken,
    IdToken: idToken,
  });

  return { refreshToken, accessToken, idToken };
};

export { APP_ID, getCurrentSession, handleAuthCode };
