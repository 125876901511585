import axios from '../xhr_libs/axios';
import { processResponse } from '.';

export const authAPI = async () => {
  return processResponse(await axios.get(`/resource/auth`));
};

export const postUserAPI = async (body) => {
  return processResponse(await axios.post('/resource/users', body));
};

export const getUserAPI = async (user) => {
  return processResponse(await axios.get(`/resource/users/${user}`));
};

export const getUsersAPI = async () => {
  return processResponse(await axios.get('/resource/users'));
};

export const putUserAPI = async (id, body) => {
  return processResponse(await axios.put(`/resource/users/${id}`, body));
};

export const deleteUserAPI = async (id) => {
  return processResponse(await axios.delete(`/resource/users/${id}`));
};

export const checkAccountReset = async (email) => {
  return processResponse(await axios.get(`/auth/account-reset/${email}`));
};

export const resendAccountCreationEmailAPI = async (body) => {
  return processResponse(
    await axios.post(`/auth/resend-confirmation-email/${body.user_id}`, body)
  );
};
