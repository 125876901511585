const ROUTES = {
  UNAUTH: {
    LANDING: '/',
    LOGIN: '/login',
    AUTH: '/auth',
    KIOSK: '/kiosk',
  },
  AUTH: {
    INDEX: '/app',
    PORTFOLIO: '/app/portfolio',
    ORGANIZATION: '/app/organization',
    SITE: '/app/site',
    METER: '/app/meter',
    ADMIN: '/app/admin',
    KPIS: '/app/kpis',
    PROFILE: '/app/profile',
    PLC: '/app/plc',
    PJM: '/app/pjm',
    TERMS: '/app/terms',
    PRIVACY: '/app/privacy',
  },
};

export default ROUTES;
