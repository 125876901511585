import { createAsyncThunk } from '@reduxjs/toolkit';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import { toastr } from 'react-redux-toastr';
// import axios from 'axios';

import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';

// import {
//   deleteImageAPI,
//   fetchImageAPI,
//   generatePutUrlAPI,
//   getImagesAPI,
//   renameImageAPI,
// } from '../../api';

const getImages = createAsyncThunk(
  'images/getImages',
  async (resourceId, { dispatch, getState, requestId }) => {
    const { currentRequestId, loading } = getState().images;

    if (loading !== true || requestId !== currentRequestId) {
      return;
    }

    try {
      dispatch(showLoading());
      // const images = await getImagesAPI(resourceId);

      // return {
      //   data: images,
      // };
    } catch (err) {
      console.error(err);
    } finally {
      dispatch(hideLoading());
    }
  }
);

const getPresignedUrl = createAsyncThunk(
  'images/getPresignedUrl',
  async ({ resourceId, key }, { dispatch, getState, requestId }) => {
    const { currentRequestId, loading, presignedUrls } = getState().images;

    if (loading !== true || requestId !== currentRequestId) {
      return;
    }

    let urls = cloneDeep(presignedUrls);
    console.log('urls: ', urls);
    console.log('resourceId: ', resourceId);
    console.log('key: ', key);
    // let resourceUrls = urls[resourceId];

    try {
      dispatch(showLoading());
      // const imageUrl = await fetchImageAPI(resourceId, key);

      // if (resourceUrls) {
      //   resourceUrls[key] = imageUrl;
      // } else {
      //   resourceUrls = { [key]: imageUrl };
      // }

      // urls[resourceId] = resourceUrls;

      // return {
      //   presignedUrls: urls,
      // };
    } catch (err) {
      console.error(err);
    } finally {
      dispatch(hideLoading());
    }
  }
);

const postImage = createAsyncThunk(
  'images/postImage',
  async (props, { dispatch, getState, requestId }) => {
    const { currentRequestId, loading } = getState().images;

    if (loading !== true || requestId !== currentRequestId) {
      return;
    }

    try {
      dispatch(showLoading());

      // const putUrl = await generatePutUrlAPI({
      //   resource_id: props.resource_id,
      //   filename: props.filename,
      //   contentType: props.image.type,
      // });

      // await axios.put(putUrl, props.image, {
      //   headers: {
      //     'Content-Type': props.image.type,
      //   },
      // });

      // toastr.success('Image saved');
    } catch (err) {
      toastr.error('Error', get(err, 'response.data.reason', err));
    } finally {
      dispatch(hideLoading());
    }
  }
);

const renameImage = createAsyncThunk(
  'images/renameImage',
  async (props, { dispatch, getState, requestId }) => {
    const { currentRequestId, loading } = getState().images;

    if (loading !== true || requestId !== currentRequestId) {
      return;
    }

    try {
      dispatch(showLoading());
      // await renameImageAPI(props);

      // toastr.success('Image updated');
    } catch (err) {
      toastr.error('Error', get(err, 'response.data.reason', err));
    } finally {
      dispatch(hideLoading());
    }
  }
);

const deleteImage = createAsyncThunk(
  'images/deleteImage',
  async (props, { dispatch, getState, requestId }) => {
    const { currentRequestId, loading } = getState().images;

    if (loading !== true || requestId !== currentRequestId) {
      return;
    }

    try {
      dispatch(showLoading());
      // await deleteImageAPI(props);

      // toastr.success('Image deleted');
    } catch (err) {
      toastr.error('Error', get(err, 'response.data.reason', err));
    } finally {
      dispatch(hideLoading());
    }
  }
);

export { getImages, postImage, renameImage, deleteImage, getPresignedUrl };
