import React from 'react';

import PropTypes from 'prop-types';

import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';

const SubHeading = ({ children }) => (
  <ListItem sx={{ pl: 0, pr: 0, pt: 1, pb: 1 }}>
    <Typography variant='h5' component='h3'>
      {children}
    </Typography>
  </ListItem>
);

SubHeading.propTypes = {
  children: PropTypes.string,
};

export default SubHeading;
