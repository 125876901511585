import { createAsyncThunk } from '@reduxjs/toolkit';
import { push } from 'redux-first-history';
import { hideLoading, showLoading } from 'react-redux-loading-bar';
import { toastr } from 'react-redux-toastr';
import cloneDeep from 'lodash/cloneDeep';
import concat from 'lodash/concat';
import get from 'lodash/get';
import includes from 'lodash/includes';
import map from 'lodash/map';
import remove from 'lodash/remove';

import ROUTES from '../../constants/routes';
import { removeTokens } from '../../helpers/auth/tokens';
import {
  authAPI,
  putUserAPI,
  getSubscriptionsAPI,
  postSubscriptionAPI,
  deleteSubscriptionAPI,
} from '../../api';
import { getOrganizations } from '../organizations';
import { getRTOs } from '../rtos';
import { setLoader } from '../pages';
import { user as initialState } from '../initialState';

const authenticateUser = createAsyncThunk(
  'user/authenticateUser',
  async (_, { dispatch }) => {
    try {
      const { user, memberships } = await authAPI();
      const subscriptions = await getSubscriptionsAPI(user.user_id);
      dispatch(getRTOs());
      dispatch(getOrganizations(user));

      return { item: user, memberships, subscriptions };
    } catch (err) {
      console.error('Authentication Error: ', err);
      dispatch(push(ROUTES.UNAUTH.LOGIN));
      dispatch(setLoader(false));
    }
  }
);

const logoutUser = createAsyncThunk(
  'user/logoutUser',
  async (_, { dispatch }) => {
    try {
      setLoader(true);
      removeTokens();
      return initialState;
    } catch (err) {
      console.error('LOGOUT FAILED', err);
    } finally {
      dispatch(push(ROUTES.UNAUTH.LOGIN));
      setLoader(false);
    }
  }
);

const putUser = createAsyncThunk(
  'user/putUser',
  async (user_item, { dispatch, getState }) => {
    try {
      const { item: user } = getState().user;
      const super_user = get(user, 'super_user', false);
      const id = get(user_item, 'user_id');

      // User can update their user or be a super user to update actual user data
      if (!super_user && id !== user.user_id) {
        return;
      }

      dispatch(showLoading());
      let updatedUser = await putUserAPI(id, user_item);

      if (updatedUser.user_id === user.user_id) {
        dispatch(getOrganizations(updatedUser));
        return { item: updatedUser };
      }
      return {};
    } catch (err) {
      toastr.error(
        'Failed to update User',
        get(err, 'response.data.reason', 'Bad Request')
      );
    } finally {
      dispatch(hideLoading());
    }
  }
);

const createSubscriptions = createAsyncThunk(
  'user/createSubscriptions',
  async (_subscriptions, { dispatch, getState }) => {
    const { subscriptions } = getState().user;
    let subscriptionsAdded = [];
    try {
      dispatch(showLoading());
      let allSubscriptions = cloneDeep(subscriptions);

      subscriptionsAdded = await Promise.all(
        map(_subscriptions, async (sub) => await postSubscriptionAPI(sub))
      );

      toastr.success('Alarm Subscription created');
      return {
        subscriptions: concat(allSubscriptions, subscriptionsAdded),
      };
    } catch (err) {
      toastr.error('Error', get(err, 'response.data.reason', err));
    } finally {
      dispatch(hideLoading());
    }
  }
);

const deleteSubscriptions = createAsyncThunk(
  'user/deleteSubscriptions',
  async (_subscriptions, { dispatch, getState }) => {
    const { subscriptions } = getState().user;
    let subscriptionsRemoved = [];
    try {
      dispatch(showLoading());
      let allSubscriptions = cloneDeep(subscriptions);
      subscriptionsRemoved = await Promise.all(
        map(
          _subscriptions,
          async (sub) => await deleteSubscriptionAPI(sub.subscription_id)
        )
      );

      // update store
      remove(allSubscriptions, (subscription) =>
        includes(subscriptionsRemoved, subscription.subscription_id)
      );

      toastr.success('Alarm Subscription deleted');
      return {
        subscriptions: allSubscriptions,
      };
    } catch (err) {
      toastr.error('Error', get(err, 'response.data.reason', err));
    } finally {
      dispatch(hideLoading());
    }
  }
);

export {
  authenticateUser,
  logoutUser,
  putUser,
  createSubscriptions,
  deleteSubscriptions,
};
