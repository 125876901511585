import axios from '../xhr_libs/axios';
import { processResponse } from '.';

export const getWeatherDataAPI = async (start, end, rto_id) => {
  return processResponse(
    await axios.get(`/resource/weather/${rto_id}`, {
      params: { start, end },
    })
  );
};
