import axios from '../xhr_libs/axios';
import { processResponse } from '.';

export const getNiagarasAPI = async () => {
  return processResponse(await axios.get('/resource/niagaras'));
};

export const putNiagaraAPI = async (body) => {
  return processResponse(
    await axios.put(`/resource/niagaras/${body.niagara_id}`, body)
  );
};

export const deleteNiagaraAPI = async (niagaraId) => {
  return processResponse(await axios.delete(`/resource/niagaras/${niagaraId}`));
};
