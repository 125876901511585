import { createAsyncThunk } from '@reduxjs/toolkit';
import { hideLoading, showLoading } from 'react-redux-loading-bar';
import { toastr } from 'react-redux-toastr';

import { cloneDeep, concat, find, get, remove } from 'lodash';
import {
  getPeakDataAPI,
  getRTODataFromFeedAPI,
  getRTOsAPI,
  putRTOAPI,
} from '../../api';

const getRTOs = createAsyncThunk('rtos/getRTOs', async (_, { dispatch }) => {
  try {
    dispatch(showLoading());
    let rtos = await getRTOsAPI();
    const selectedRTO = find(rtos, { rto_id: 'PJM' });
    return { data: rtos, selectedRTO };
  } catch (err) {
    console.error(err);
  } finally {
    dispatch(hideLoading());
  }
});

const putRTO = createAsyncThunk(
  'rtos/putRTO',
  async (updatedRTO, { dispatch, getState, requestId }) => {
    try {
      const { item: user } = getState().user;
      const { currentRequestId, loading, data: rtos } = getState().rtos;
      const super_user = get(user, 'super_user', false);

      if (!super_user || loading !== true || requestId !== currentRequestId) {
        return;
      }

      dispatch(showLoading());
      let _updatedRTO = await putRTOAPI(updatedRTO);

      toastr.success('RTO updated', get(_updatedRTO, 'name'));

      let _rtos = cloneDeep(rtos);
      remove(_rtos, { rto_id: get(updatedRTO, 'rto_id') });
      _rtos = concat(_rtos, _updatedRTO);
      return { data: _rtos };
    } catch (err) {
      console.error(err);
    } finally {
      dispatch(hideLoading());
    }
  }
);

const getInstLoadData = createAsyncThunk(
  'rtos/getInstLoadData',
  async ({ start, end }, { dispatch, getState, requestId }) => {
    const { selectedRTO, loading, currentRequestId } = getState().rtos;

    if (!loading || requestId !== currentRequestId) {
      return;
    }

    try {
      dispatch(showLoading());
      let records = await getRTODataFromFeedAPI(
        'inst_loads',
        selectedRTO.rto_id,
        start.unix(),
        end.unix()
      );

      return { instLoadData: records };
    } catch (err) {
      console.error(err);
    } finally {
      dispatch(hideLoading());
    }
  }
);

const getPeakData = createAsyncThunk(
  'rtos/getPeakData',
  async (_, { dispatch, getState, requestId }) => {
    const { selectedRTO, loading, currentRequestId } = getState().rtos;
    if (!loading || requestId !== currentRequestId) {
      return;
    }

    try {
      dispatch(showLoading());
      let peaks = await getPeakDataAPI(selectedRTO.rto_id);
      return { peaks };
    } catch (err) {
      console.error(err);
    } finally {
      dispatch(hideLoading());
    }
  }
);

export { getRTOs, putRTO, getInstLoadData, getPeakData };
