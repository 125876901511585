import axios from '../xhr_libs/axios';
import { processResponse } from '.';

export const getMembershipsAPI = async () => {
  return processResponse(await axios.get(`/resource/memberships`));
};

export const postMembershipAPI = async (membership) => {
  return processResponse(await axios.post('/resource/memberships', membership));
};

export const putMembershipAPI = async (membership) => {
  return processResponse(
    await axios.put(
      `/resource/memberships/${membership?.org_id}/${membership?.user_id}`,
      membership
    )
  );
};

export const deleteMembershipAPI = async (orgId, userId) => {
  return processResponse(
    await axios.delete(`/resource/memberships/${orgId}/${userId}`)
  );
};
