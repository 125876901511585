import { createAsyncThunk } from '@reduxjs/toolkit';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import { toastr } from 'react-redux-toastr';
import { cloneDeep, concat, get, remove } from 'lodash';
import { getNiagarasAPI, deleteNiagaraAPI, putNiagaraAPI } from '../../api';

const getNiagaras = createAsyncThunk(
  'niagaras/getNiagaras',
  async (_, { getState, requestId }) => {
    const { currentRequestId, loading } = getState().niagaras;
    if (loading !== true || requestId !== currentRequestId) {
      return;
    }

    return { data: await getNiagarasAPI() };
  }
);

const putNiagara = createAsyncThunk(
  'niagaras/putNiagara',
  async (
    { active, devices, niagara_id, org_id },
    { dispatch, getState, requestId }
  ) => {
    try {
      const {
        currentRequestId,
        loading,
        data: allNiagaras,
      } = getState().niagaras;
      const { item: user } = getState().user;

      if (loading !== true || requestId !== currentRequestId) {
        return;
      }

      if (get(user, 'super_user')) {
        dispatch(showLoading());
        let _niagaras = cloneDeep(allNiagaras);

        let updatedNiagara = await putNiagaraAPI({
          active,
          devices,
          niagara_id: niagara_id,
          org_id: org_id,
        });
        remove(_niagaras, { niagara_id: get(updatedNiagara, 'niagara_id') });
        toastr.success('Niagara instance updated', get(updatedNiagara, 'name'));
        return { data: concat(_niagaras, updatedNiagara) };
      }
      return {};
    } catch (err) {
      console.error(err);
    } finally {
      dispatch(hideLoading());
    }
  }
);

const deleteNiagara = createAsyncThunk(
  'niagaras/deleteNiagara',
  async (niagara, { dispatch, getState, requestId }) => {
    try {
      const {
        currentRequestId,
        loading,
        data: allNiagaras,
      } = getState().niagaras;
      const { item: user } = getState().user;

      if (loading !== true || requestId !== currentRequestId) {
        return;
      }

      if (get(user, 'super_user')) {
        dispatch(showLoading());
        let _niagaras = cloneDeep(allNiagaras);
        await deleteNiagaraAPI(get(niagara, 'niagara_id'));
        toastr.success('Niagara instance deleted', get(niagara, 'name'));
        remove(_niagaras, { niagara_id: get(niagara, 'niagara_id') });
        return { data: _niagaras };
      }
      return {};
    } catch (err) {
      console.error(err);
    } finally {
      dispatch(hideLoading());
    }
  }
);

export { getNiagaras, putNiagara, deleteNiagara };
